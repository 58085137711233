@font-face {
  font-family: "BM";
  src: url("BM.ttf") format("woff2");
}

.gowun-dodum-regular {
  font-family: "Gowun Dodum", sans-serif;
  font-weight: 400;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f1f3f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.icon {
  text-align: center;
  font-size: 100px;
}

.title {
  font-family: Gowun Dodum;
  font-size: 40px;
  margin-bottom: 10px;
  text-align: center;
  font-weight: 800;
}

.title.result {
  margin-top: 50px;
}

.subtitle {
  margin: 10px;
  font-family: Gowun Dodum;
  font-size: 20px;
}

.explanation {
  text-align: center;
  color: gray;
  font-family: Gowun Dodum;
}

.line {
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 50px;
  width: 500px;
  height: 1px;
  background-color: gray;
}

.modal {
  display: flex;
  flex-direction: column;
  width: 700px;
  height: 400px;
  background-color: #fff;
  border-radius: 10px;
}

.saeteuk {
  width: 95%;
  height: 300px;
  resize: none;
  border: none;
  background-color: #ffffff;
  margin-top: 5px;
  border-radius: 10px;
}

.center {
  margin: 0 auto;
}

.create {
  margin-top: 10px;
  margin-bottom: 10px;
  height: 35px;
  background-color: #C4BEE2;
  width: 95%;
  border-radius: 10px;
  border: none;
  color: white;
  font-family: Gowun Dodum;
  font-weight: 800;
  font-size: 15px;
  display: flex;
  justify-content: center; /* 수평 중앙 정렬 */
  align-items: center;  
}

.queWrapper {
  display: flex;
}

.ansWrapper {
  display: flex;
}

.qTitle {
  font-family: Gowun Dodum;
  font-size: 25px;
  margin-right: 10px;
}

.aTitle {
  font-family: Gowun Dodum;
  font-size: 25px;
  margin-right: 10px;
  color: rgb(165, 0, 0);
}

.question-container {
  background-color: #fff;
  border-radius: 20px;
  padding: 15px;
  width: 950px;
  margin: 0 auto;
  margin-top: 20px;
}

.Q {
  font-family: Gowun Dodum;
  font-weight: 800;
  font-size: 20px;
  text-align: left;
  margin-top: 5px;
}

.A {
  font-weight: 400;
  font-family: Gowun Dodum;
  font-size: 15px;
  text-align: left;
  margin-top: 5px;
}

.modal-content {
  display: flex;
  align-items: center;
}

.modal_r {
  height: 70px;
  width: 100%;
}

.score {
  font-family: Gowun Dodum;
  font-weight: 400;
  font-size: 25px;
}

.answer-box {
  width: 100%;
  height: 50px;
  background-color: transparent;
  border: none;
  resize: none;
}

.submit-button {
  height: 50px;
  width: 50px;
  background-color: transparent;
  border: none;
  color: #000;
  font-family: Gowun Dodum;
  font-size: 15px;
  font-weight: 800;
  border-radius: 0px 10px 10px 0;
}

.bottomSpace {
  margin-top: 100px;
}

.alertBg {
  position: fixed;
  top:0; left: 0; bottom: 0; right: 0;
  background: rgba(0, 0, 0, 0.5);
}

.alertWrapper {
  position: absolute;
  top: calc(50vh - 100px); left: calc(50vw - 200px);
  background-color: #f1f3f5;
  align-items: center;
  border-radius: 10px;
  width: 400px;
  height: 200px;
}

.alertTop {
  margin: 0 auto;
  margin-bottom: 20px;
  font-family: Gowun Dodum;
  font-size: 25px;
  font-weight: 500;
}

.alertMsg{
  font-family: Gowun Dodum;
  font-size: 20px;
  height: 100px;
}

.alertClose {
  margin-top: 10px;
  margin-bottom: 10px;
  height: 35px;
  background-color: #C4BEE2;
  width: 95%;
  border-radius: 10px;
  margin: 0 auto;
  border: none;
  color: white;
  font-family: Gowun Dodum;
  font-weight: 800;
  font-size: 15px;
  display: flex;
  justify-content: center; /* 수평 중앙 정렬 */
  align-items: center;  
}

.spinner-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.2); /* 배경 반투명 */
}

/* 로딩 스피너 */
.spinner {
  border: 4px solid #f3f3f3; /* 배경 원 */
  border-top: 4px solid #C4BEE2; /* 애니메이션 원 */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

/* 로딩 애니메이션 */
@keyframes spin {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

.coming-soon-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: #f1f3f5;
  color: black;
  font-family: Gowun Dodum;
  font-size: 24px;
  text-align: center;
}

.copySpan {
  font-family: Gowun Dodum;
  font-size: 15px;
  padding: 10px;
}