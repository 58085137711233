body {
    text-align: center;
    background-color: #f1f3f5;
}

.gowun-dodum-regular {
    font-family: "Gowun Dodum", sans-serif;
    font-weight: 400;
    font-style: normal;
  }

.title404 {
    margin-top: 100px;
    font-family: Gowun Dodum;
    font-weight: 800;
    font-size: 50px;
}

.explain {
    font-family: Gowun Dodum;
    font-size: 20px;
    color: gray;
}

.back {
    height: 35px;
    background-color: #C4BEE2;
    width: 350px;
    border-radius: 10px;
    color: white;
    font-family: Gowun Dodum;
    font-size: 15px;
    display: flex;
    justify-content: center; /* 수평 중앙 정렬 */
    align-items: center;  
    margin: 0 auto;
    margin-top: 300px;
  }